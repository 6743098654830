<template>
  <div class="content-container">
    <PageTitle :title="$t('TEACHER_OVERVIEW.TEACHERS')" class="page-title">
      <template #extra-content>
        <div class="top-right-buttons-wrapper">
          <!-- <BasicButton class="export-email-button pink-button" :button-text="$t('TEACHERS_HELPDESK.EXPORT_EMAILS')" @click="preExportEmails" /> -->
          <BasicButton :button-text="$t('CREATE_TEACHER_TEXT')" @click="createTeacher()"/>
        </div>
      </template>
    </PageTitle>
    <h1 class="page-section-title section-title">{{ $t('TEACHERS_HELPDESK.OVERVIEW') }}</h1>

    <CustomTable
      ref="customTable"
      :options="options"
      :less-padding="true"
      :table-data="searchResults.items"
      :pagination-data="searchResults"
      :search-bar-options="
        {
          placeholder: $t('TEACHERS_HELPDESK.SEARCH_PLACEHOLDER'),
          searchId: 'search-teacher'
        }"
      :search-function="manageSearch"
      :filter-data="filterData"
      :no-results-data="noResultsData"
    >
      <!--selectedTeachers.find(teacher => teacher.id === props.rowData.id) !== null"-->
      <template #selected="props">
        <BasicCheckbox class="table-checkbox"
                       :is-checkbox-checked="selectedTeachers.find(teacher => teacher.userId === props.rowData.userId) !== undefined"
                       :id="props.rowData.userId.toString()"
                       custom-class="small-gray"
                       :label-text="null"
                       :callback="() => toggleCheckbox(props.rowData)"
        />
      </template>
      <template #name="props">
        <div> {{ props.rowData.name }}</div>
      </template>
      <template #email="props">
        <div><a :href="'mailto:' + props.rowData.email" class="link-main">{{ props.rowData.email }}</a></div>
      </template>
      <template #schoolTypeName="props">
        <div> {{ props.rowData.schoolTypeName }}</div>
      </template>
      <template #createdAt="props">
        <div> {{ props.rowData.createdAtString }}</div>
      </template>
      <template #lastLogin="props">
        <div> {{ props.rowData.lastLoginString }}</div>
      </template>
      <template #accountState="props">
        <div v-if="props.rowData.accountState" :class="props.rowData.accountState.customClass" class="approval-box">
          {{ props.rowData.accountState.name }}
        </div>
      </template>
      <template #actions="props">
        <div class="action-button-group">
          <ActionButton
            :text="$t('VIEW')"
            :icon="require('../../../assets/icons/icn_visibility_white.svg')"
            custom-class="button-blue"
            class="action"
            @click="teacherDetails(props.rowData)"
          />
        </div>
      </template>
      <template #right-side-info>
        <p>{{ searchResults.totalItems + " " + $t('TEACHERS_HELPDESK.TOTAL_TEACHERS') }}</p>
      </template>
      <template #extra-dropdowns>
        <div class="accept-or-decline-buttons">
          <BasicButton :disabled="!enableAcceptDeclineButtons" class="pink-button table-button"
                       :button-text="$t('TEACHERS_HELPDESK.ACCEPT')" @click="approveAllTeachers()"/>
          <BasicButton :disabled="!enableAcceptDeclineButtons" class="red-button table-button"
                       :button-text="$t('TEACHERS_HELPDESK.DECLINE')" @click="declineAllTeachers()"/>
        </div>
      </template>
    </CustomTable>

    <BasicModal v-if="isBasicVersionDisplayed">
      <template #content>
        <div class="delete-confirmations">
          <DateSelector
            :on-change="exportEmailDate"
            :start-date="new Date()"
            :clearable="false"
            :title="'Datum'"/>
          <BasicCheckbox :id="'onlyActive'"
                         :label-text="$t('TEACHERS_HELPDESK.EXPORT_ONLY_ACTIVE')"
                         custom-class="small-gray"
                         :callback="onCheckboxChange"/>
        </div>

      </template>
    </BasicModal>
    <BasicModal v-if="isAcceptingOrDecliningBulk !== 0">
    </BasicModal>
  </div>
</template>

<script>
import DateSelector from '@/components/elements/inputFields/DateSelector'
import PageTitle from '@/components/elements/texts/PageTitle'
import CustomTable from '@/components/table/CustomTable'
import BasicCheckbox from '@/components/elements/inputFields/BasicCheckbox'

import ActionButton from '@/components/table/ActionButton'
import {computed, ref} from 'vue'
import {getTableOptions, TEACHERS_TABLE} from '@/utils/helpers/customTable/CustomTableHelper'
import {GET_TEACHERS} from '@/store/modules/helpdesk/actions'
import {GET_TEACHER_TYPES} from '@/store/modules/auth/actions'
import {useRouter} from 'vue-router'
import {useStore} from 'vuex'
import {ROUTE_NAMES_HELPDESK} from '@/router/modules/helpdesk'
import {createNoResultsData} from '@/utils/helpers/customTable/EmptyTableHelper'
import {formatDateTimeString, showJustDate} from '@/utils/helpers/DateFormatter'
import {USER_TYPE_IDS} from '@/models/UserTypeIds'
import BasicButton from '../../../components/elements/basic/BasicButton.vue'

import BasicModal from '@/components/elements/basicModal/BasicModal'
import {BASIC_MODAL_CONTENT} from '@/models/basicModal/BasicModalContent'
import ModalController, {MODAL_NAMES} from '@/utils/helpers/basicModal/ModalController'
import ModalButton from "@/components/elements/basicModal/ModalButton";
import ApiHandler from "@/utils/handlers/ApiHandler";

export default {
  name: 'TeachersOverview',
  components: {
    ModalButton,
    PageTitle,
    CustomTable,
    ActionButton,
    BasicButton,
    BasicModal,
    DateSelector,
    BasicCheckbox
  },
  setup(props, context) {
    const router = useRouter()
    const store = useStore()

    const accountStateOptions = [
      {
        id: 1,
        name: 'In afwachting goedkeuring',
        customClass: 'pending'
      },
      {
        id: 2,
        name: 'Goedgekeurd',
        customClass: 'approved',
      },
      {
        id: 3,
        name: 'Uitgenodigd door helpdesk, nog geen wachtwoord ingesteld',
        customClass: 'invitedByHelpdesk',
      },
      {
        id: 4,
        name: 'Uitgenodigd door leraar, nog geen wachtwoord ingesteld',
        customClass: 'invitedByTeacher',
      },
      {
        id: 5,
        name: 'Oud MM account, nog geen wachtwoord ingesteld',
        customClass: 'oldMMAccount',
      },
      {
        id: 6,
        name: 'E-mailadres niet geverifieerd',
        customClass: 'emailNotVerified',
      },
      {
        id: 7,
        name: 'In registratie',
        customClass: 'inRegistration',
      },
    ]

    const searchResults = computed(() => {
      const teachers = store.getters.getTeachers

      // console.log('store.getters.getTeachers', store.getters.getTeachers)
      if (teachers.items && teachers.items.length) {
        teachers.items.forEach((teacher) => {
          teacher.lastLoginString = teacher.lastLogin ? formatDateTimeString(teacher.lastLogin) : 'Nog nooit'
          teacher.createdAtString = formatDateTimeString(teacher.createdAt)
          // console.log('teacher', teacher)

          if (!teacher.schoolName) {
            teacher.accountState = accountStateOptions[6]
          } else if (!teacher.emailVerified) {
            teacher.accountState = accountStateOptions[5]
          } else if (!teacher.hasSetPassword) {
            if (teacher.inviterUserType === USER_TYPE_IDS.HELPDESK) {
              teacher.accountState = accountStateOptions[2]
            } else if (teacher.inviterUserType === USER_TYPE_IDS.TEACHER) {
              teacher.accountState = accountStateOptions[3]
            } else {
              teacher.accountState = accountStateOptions[4]
            }
          } else if (teacher.approvedOn) {
            teacher.accountState = accountStateOptions[1]
          } else {
            teacher.accountState = accountStateOptions[0]
          }
        })
      }

      // console.log('teachers', teachers)
      return teachers
    })
    const options = computed(() => getTableOptions(TEACHERS_TABLE))
    const filters = computed(() => store.getters.getTeacherTypes)
    const customTable = ref(null)


    const modalController = ref(new ModalController())
    const activeModal = computed(() => store.getters.getActiveBasicModal)
    const isBasicVersionDisplayed = computed(() =>
      activeModal.value === MODAL_NAMES.EXPORT_EMAILS
    )


    function preExportEmails() {
      modalController.value.setModal(BASIC_MODAL_CONTENT.EXPORT_EMAILS, exportEmails, null, null, MODAL_NAMES.EXPORT_EMAILS)
    }

    function exportEmailDate(date) {
      startDate.value = date
    }

    const startDate = ref(null)
    const checkbox = ref(false)

    function onCheckboxChange(id, data, value) {
      checkbox.value = value
    }

    function exportEmails() {
      modalController.value.resetActiveModal()
    }

    // manageSearch()


    function manageSearch(payload) {
      if (!payload) {
        payload = {}
      }
      console.log('manageSearch', payload)
      searchTeachers(payload)
    }

    const noResultsData = computed(() => {
      if (!customTable.value) {
        return {}
      }

      return createNoResultsData('TEACHERS_HELPDESK', require('@/assets/icons/icn_friends.svg'), customTable.value.lastSearch)
    })

    const filterData = computed(() => {
      return {
        placeholder: 'Filter',
        singleSelection: false,
        dropdownOptions:
          [
            {
              name: 'Type onderwijs',
              type: 'schoolTypeId',
              options: filters.value,
            },
            {
              name: 'Account status',
              type: 'accountState',
              singleSelection: true,
              options: accountStateOptions,
            }
          ],
      }
    })

    function searchTeachers(payload) {
      store.dispatch(GET_TEACHERS, payload)
    }

    getTeacherTypes()

    function getTeacherTypes() {
      store.dispatch(GET_TEACHER_TYPES)
    }


    function teacherDetails(data) {
      const teacherId = data.userId
      router.push({name: ROUTE_NAMES_HELPDESK.TEACHER_DETAILS, params: {teacherId: teacherId}})
    }

    function createTeacher() {
      router.push({name: ROUTE_NAMES_HELPDESK.TEACHER_CREATE})
    }


    const selectedTeachers = ref([],)

    function toggleCheckbox(teacher) {
      if (selectedTeachers.value.find(t => t.userId === teacher.userId)) {
        selectedTeachers.value = selectedTeachers.value.filter(t => t.userId !== teacher.userId)
      } else {
        selectedTeachers.value.push(teacher)
      }
      console.log('selectedTeachers', selectedTeachers.value);
    }

    const enableAcceptDeclineButtons = computed(() => {
      return selectedTeachers.value.length > 0 && selectedTeachers.value.some(t => t.accountState.id === 1)
    })

    /**
     * 0 = not accepting or declining, -1 = declining, 1 = accepting
     * @type {Ref<UnwrapRef<number>>}
     */
    const isAcceptingOrDecliningBulk = ref(0);


    const teachersNamesThatCanBeAccepted = computed(() => {
      return selectedTeachers.value.filter(t => t.accountState.id === 1);
    })

    const teachersNamesThatCantBeAccepted = computed(() => {
      return selectedTeachers.value.filter(t => t.accountState.id !== 1);
    })

    function approveAllTeachers() {
      isAcceptingOrDecliningBulk.value = 1;
      modalController.value.setModal(BASIC_MODAL_CONTENT.BULK_ACCEPT_DECLINE({
        isApproving: true,
        classes: teachersNamesThatCanBeAccepted.value.map(t => t.firstName + ' ' + t.lastName).join(', '),
        invalidClasses: teachersNamesThatCantBeAccepted.value.map(t => t.firstName + ' ' + t.lastName).join(', '),
      }), postConfirmBulkAcceptDecline, null, null, MODAL_NAMES.BULK_ACCEPT_DECLINE)
    }

    function declineAllTeachers() {
      isAcceptingOrDecliningBulk.value = -1;
      modalController.value.setModal(BASIC_MODAL_CONTENT.BULK_ACCEPT_DECLINE({
        isApproving: false,
        classes: teachersNamesThatCanBeAccepted.value.map(t => t.firstName + ' ' + t.lastName).join(', '),
        invalidClasses: teachersNamesThatCantBeAccepted.value.map(t => t.firstName + ' ' + t.lastName).join(', '),
      }), postConfirmBulkAcceptDecline, null, null, MODAL_NAMES.BULK_ACCEPT_DECLINE)

    }

    function postConfirmBulkAcceptDecline() {

      //TODO add this functionality
      console.log("we were supposed to bulk accept or decline teachers here", isAcceptingOrDecliningBulk.value);
      approveOrDecline(isAcceptingOrDecliningBulk.value === 1);
      isAcceptingOrDecliningBulk.value = 0;


    }


    function approveOrDecline(approve) {
      const teacherIds = teachersNamesThatCanBeAccepted.value.map(t => t.userId);
      const promises = teacherIds.map(teacherId => {
        return new Promise((resolve, reject) => {
          const formData = new FormData()
          formData.append('teacherId', teacherId)
          ApiHandler.post(`helpdesk/teacher/${(approve ? 'approve' : 'decline')}-teacher`, formData).then(response => {
            resolve(response)
          }).catch(error => {
            reject(error)
          })
        })
      });

      //await all promises
      Promise.all(promises).then(() => {
        searchTeachers({})
        modalController.value.resetActiveModal()
      }).catch(error => {
        console.error('error', error)
        searchTeachers({})
        modalController.value.resetActiveModal()
      })
    }

    return {
      teachersNamesThatCantBeAccepted,
      teachersNamesThatCanBeAccepted,
      enableAcceptDeclineButtons,
      selectedTeachers,
      toggleCheckbox,
      searchResults,
      filterData,
      options,
      manageSearch,
      ROUTE_NAMES_HELPDESK,
      noResultsData,
      teacherDetails,
      customTable,
      showJustDate,
      approveAllTeachers,
      declineAllTeachers,

      isBasicVersionDisplayed,
      // preExportEmails,
      // accountStateOptions,
      // exportEmailDate,
      startDate,
      onCheckboxChange,
      createTeacher,
      isAcceptingOrDecliningBulk,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.approval-box {
  padding: 0.4rem;
  text-align: center;
  border-radius: rem(5);
  min-width: rem(120);
  max-width: rem(270);

  &.pending {
    color: rgb(248, 170, 105);
    background-color: #FDF2E8;
  }

  &.approved {
    color: #37c6be;
    background-color: #e5f7f6;
  }

  &.invitedByTeacher, &.invitedByHelpdesk, &.inRegistration {
    color: #ccb528;
    background-color: #f7f7e5;
  }

  &.oldMMAccount {
    background-color: #f6f7fa;
    color: #8d877c;
  }

  &.emailNotVerified {
    color: #aa0019;
    background-color: #f7d3ce;
  }
}

.create-teacher {
  font-size: rem(16);
  width: rem(210);
  height: rem(41);
  padding: 0;
  line-height: rem(41);
}

.top-right-buttons-wrapper {
  display: flex;
  gap: rem(15);
}

.export-email-button {
  font-size: rem(16);
  height: rem(41);
  padding: 0 rem(10);
  line-height: rem(41);
  background: #0E4E95
}

.table-checkbox {
  color: #0E4E95;
}

.accept-or-decline-buttons {
  display: flex;
  white-space: nowrap;
  gap: rem(10);
}

.table-button {
  //max-width: rem(200);
}

</style>
